<template>
  <div>
    <slot name="trigger" :open="open" />

    <!-- Overlay -->
    <transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="fixed inset-0 z-40 transition-opacity"
        @click="overlayClose"
      >
        <div class="w-full h-full" :class="overlay"></div>
      </div>
    </transition>

    <!-- Dialog -->
    <transition :name="transition">
      <div
        v-if="isOpen"
        class="fixed z-50 transition-all transform"
        :class="[position]"
      >
        <slot name="content" :close="close" />
      </div>
    </transition>

    <!-- Close Button -->
    <slot name="closeButton">
      <div
        v-if="isOpen"
        class="fixed z-50 text-xl text-text_alt_color cursor-pointer top-10 right-10"
        @click="close"
      >
        X
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    intialValue?: boolean
    overlay?: string
    transition?: string
    position?: string
    closable?: boolean
  }>(),
  {
    intialValue: false,
    overlay: 'bg-gray-500 opacity-50',
    transition: 'dialog-scale',
    position: 'top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    closable: true
  }
)

const isOpen = ref(props.intialValue)

function open() {
  isOpen.value = true
  if (document) { document.body.style.overflow = 'hidden' }
}

function close() {
  isOpen.value = false
  if (document) { document.body.style.overflow = 'auto' }
}

function overlayClose() {
  if (props.closable) { close() }
}
</script>

<style scoped>
.dialog-scale-enter-active {
  @apply ease-out duration-300;
}
.dialog-scale-leave-active {
  @apply ease-in duration-200;
}
.dialog-scale-enter-from,
.dialog-scale-leave-to {
  @apply scale-0 opacity-0;
}
</style>
