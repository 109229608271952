<template>
  <div class="overflow-hidden">
    <div class="iuc-cropper-wrapper" style="display: block">
      <div
        class="cropper-container cropper-bg"
        touch-action="none"
        :style="`width:${containerWidth}px; height:${containerHeight}px`"
        :class="{ 'hidden': !src?.length }"
        style="margin: auto"
      >
        <img
          v-show="src"
          :id="`cropImage${containerId}`"
          style="display: block"
          max-width="100%"
          :src="src||undefined"
          alt=""
        />
        <img v-if="!src" width="100%" :src="initialImage" alt="" />
      </div>
    </div>
    <input
      ref="imageToCrop"
      type="file"
      accept="image/*"
      hidden
      @change="selectImageToCrop"
    />
    <slot
      name="button"
      :pick-image="pickImage"
      :picking="src != null"
      :src="src"
      :clear="clear"
    >
    </slot>
  </div>
</template>

<script setup lang="ts">
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

const props = withDefaults(
  defineProps<{
    initialImage?: string
    dragMode?: string
    resizable?: boolean
    movable?: boolean
    ratio?: number
    containerWidth?: number
    containerHeight?: number
    containerId?: number
  }>(),
  {
    initialImage: undefined,
    dragMode: 'move',
    resizable: true,
    movable: true,
    ratio: 1,
    containerWidth: 200,
    containerHeight: 200,
    containerId: 1
  }
)

const emit = defineEmits(['cropped'])

const cropper = ref<null | Cropper>(null)
const src = ref<string | null>(null)
const width = ref(1000)
const imageToCrop = ref<HTMLInputElement | null>(null)

watch(src, (value) => {
  if (value) {
    if (cropper.value) {
      cropper.value?.replace(value)
    } else {
      cropData()

      cropper.value?.replace(value)
    }
  }
})

function pickImage() {
  imageToCrop.value?.click()
}

function clear() {
  src.value = null
  cropper.value?.destroy()
  cropper.value = null
  if (imageToCrop.value) {
    imageToCrop.value.value = ''
  }
}

function save() {
  const canvas = cropper.value?.getCroppedCanvas({
    width: width.value,
    height: width.value * props.ratio,
    fillColor: '#fff'
  })

  canvas?.toBlob((o: any) => {
    const cropped: { image: string; thumb: string } = { image: '', thumb: '' }
    cropped.image = o
    cropped.thumb = canvas.toDataURL('image/jpeg')
    emit('cropped', cropped)
  })
}

function cropData() {
  cropper.value = new Cropper(
    document.querySelector(`#cropImage${props.containerId}`),
    {
      dragMode: 'move',
      cropBoxResizable: false,
      cropBoxMovable: false,
      viewMode: 3,
      background: true,
      autoCropArea: 1,
      center: true,
      autoCrop: true,
      movable: true,
      aspectRatio: NaN,
      guides: false,
      highlight: false,
      minContainerWidth: +props.containerWidth,
      minContainerHeight: +props.containerHeight,
      minCropBoxWidth: +props.containerWidth,
      minCropBoxHeight: +props.containerHeight,
      minCanvasWidth: +props.containerWidth,
      minCanvasHeight: +props.containerHeight,

      ready(_event: any) {
        save()
      },

      zoom() {
        save()
      },

      cropend(_event: any) {
        save()
      }
    }
  )
}

function selectImageToCrop(_v: any) {
  const reader = new FileReader()
  reader.onload = (e) => {
    src.value = e.target?.result as string
  }
  if (imageToCrop.value?.files?.length) {
    reader.readAsDataURL(imageToCrop.value.files[0])
  }
}
</script>
